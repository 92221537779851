// 定义内容
export default {
	router: {
		home: '首頁',
		system: '系統設置',
		systemMenu: '選單管理',
		systemRole: '角色管理',
		systemUser: '用戶管理',
		systemDept: '部門管理',
		systemDic: '字典管理',
		limits: '許可權管理',
		limitsFrontEnd: '前端控制',
		limitsFrontEndPage: '頁面許可權',
		limitsFrontEndBtn: '按鈕許可權',
		limitsBackEnd: '後端控制',
		limitsBackEndEndPage: '頁面許可權',
		menu: '選單嵌套',
		menu1: '選單1',
		menu11: '選單11',
		menu12: '選單12',
		menu121: '選單121',
		menu122: '選單122',
		menu13: '選單13',
		menu2: '選單2',
		funIndex: '功能',
		funTagsView: 'tagsView 操作',
		funCountup: '數位滾動',
		funWangEditor: 'Editor 編輯器',
		funCropper: '圖片裁剪',
		funQrcode: '二維碼生成',
		funEchartsMap: '地理座標/地圖',
		funPrintJs: '頁面列印',
		funClipboard: '複製剪切',
		funGridLayout: '拖拽佈局',
		funSplitpanes: '窗格折開器',
		funDragVerify: '驗證器',
		pagesIndex: '頁面',
		pagesFiltering: '過濾篩選組件',
		pagesFilteringDetails: '過濾篩選組件詳情',
		pagesFilteringDetails1: '過濾篩選組件詳情111',
		pagesIocnfont: 'ali 字體圖標',
		pagesElement: 'ele 字體圖標',
		pagesAwesome: 'awe 字體圖標',
		pagesFormAdapt: '表單自我調整',
		pagesTableRules: '表單表格驗證',
		pagesFormI18n: '表單國際化',
		pagesFormRules: '多表單驗證',
		pagesDynamicForm: '動態複雜表單',
		pagesWorkflow: '工作流',
		pagesListAdapt: '清單自我調整',
		pagesWaterfall: '瀑布屏',
		pagesSteps: '步驟條',
		pagesPreview: '大圖預覽',
		pagesWaves: '波浪效果',
		pagesTree: '樹形改表格',
		pagesDrag: '拖動指令',
		pagesLazyImg: '圖片懶加載',
		makeIndex: '組件封裝',
		makeSelector: '圖標選擇器',
		makeNoticeBar: '滾動通知欄',
		makeSvgDemo: 'svgIcon 演示',
		makeTableDemo: '表格封裝演示',
		paramsIndex: '路由參數',
		paramsCommon: '普通路由',
		paramsDynamic: '動態路由',
		paramsCommonDetails: '普通路由詳情',
		paramsDynamicDetails: '動態路由詳情',
		chartIndex: '大資料圖表',
		visualizingIndex: '數據視覺化',
		visualizingLinkDemo1: '數據視覺化演示1',
		visualizingLinkDemo2: '數據視覺化演示2',
		personal: '個人中心',
		tools: '工具類集合',
		layoutLinkView: '外鏈',
		layoutIframeViewOne: '内嵌 iframe1',
		layoutIframeViewTwo: '内嵌 iframe2',
	},
	staticRoutes: {
		signIn: '登入',
		notFound: '找不到此頁面',
		noPower: '沒有許可權',
	},
	user: {
		title0: '組件大小',
		title1: '語言切換',
		title2: '選單蒐索',
		title3: '佈局配寘',
		title4: '消息',
		title5: '開全屏',
		title6: '關全屏',
		dropdownLarge: '大型',
		dropdownDefault: '默認',
		dropdownSmall: '小型',
		dropdown1: '首頁',
		dropdown2: '個人中心',
		dropdown3: '404',
		dropdown4: '401',
		dropdown5: '登出',
		dropdown6: '程式碼倉庫',
		searchPlaceholder: '選單蒐索：支援中文、路由路徑',
		newTitle: '通知',
		newBtn: '全部已讀',
		newGo: '前往通知中心',
		newDesc: '暫無通知',
		logOutTitle: '提示',
		logOutMessage: '此操作將登出，是否繼續？',
		logOutConfirm: '確定',
		logOutCancel: '取消',
		logOutExit: '退出中',
	},
	tagsView: {
		refresh: '重繪',
		close: '關閉',
		closeOther: '關閉其它',
		closeAll: '全部關閉',
		fullscreen: '當前頁全屏',
		closeFullscreen: '關閉全屏',
	},
	notFound: {
		foundTitle: '地址輸入錯誤，請重新輸入地址~',
		foundMsg: '您可以先檢查網址，然後重新輸入或給我們迴響問題。',
		foundBtn: '返回首頁',
	},
	noAccess: {
		accessTitle: '您未被授權，沒有操作許可權~',
		accessMsg: '聯繫方式：加QQ群探討665452019',
		accessBtn: '重新授權',
	},
	layout: {
		configTitle: '佈局配寘',
		oneTitle: '全域主題',
		twoTopTitle: '頂欄設定',
		twoMenuTitle: '選單設定',
		twoColumnsTitle: '分欄設定',
		twoTopBar: '頂欄背景',
		twoTopBarColor: '頂欄默認字體顏色',
		twoIsTopBarColorGradual: '頂欄背景漸變',
		twoMenuBar: '選單背景',
		twoMenuBarColor: '選單默認字體顏色',
		twoMenuBarActiveColor: '選單高亮背景色',
		twoIsMenuBarColorGradual: '選單背景漸變',
		twoColumnsMenuBar: '分欄選單背景',
		twoColumnsMenuBarColor: '分欄選單默認字體顏色',
		twoIsColumnsMenuBarColorGradual: '分欄選單背景漸變',
		twoIsColumnsMenuHoverPreload: '分欄選單滑鼠懸停預加載',
		threeTitle: '介面設定',
		threeIsCollapse: '選單水准折疊',
		threeIsUniqueOpened: '選單手風琴',
		threeIsFixedHeader: '固定 Header',
		threeIsClassicSplitMenu: '經典佈局分割選單',
		threeIsLockScreen: '開啟鎖屏',
		threeLockScreenTime: '自動鎖屏(s/秒)',
		fourTitle: '介面顯示',
		fourIsShowLogo: '側邊欄 Logo',
		fourIsBreadcrumb: '開啟 Breadcrumb',
		fourIsBreadcrumbIcon: '開啟 Breadcrumb 圖標',
		fourIsTagsview: '開啟 Tagsview',
		fourIsTagsviewIcon: '開啟 Tagsview 圖標',
		fourIsCacheTagsView: '開啟 TagsView 緩存',
		fourIsSortableTagsView: '開啟 TagsView 拖拽',
		fourIsShareTagsView: '開啟 TagsView 共用',
		fourIsFooter: '開啟 Footer',
		fourIsGrayscale: '灰色模式',
		fourIsInvert: '色弱模式',
		fourIsDark: '深色模式',
		fourIsWatermark: '開啟浮水印',
		fourWatermarkText: '浮水印文案',
		fiveTitle: '其它設定',
		fiveTagsStyle: 'Tagsview 風格',
		fiveAnimation: '主頁面切換動畫',
		fiveColumnsAsideStyle: '分欄高亮風格',
		fiveColumnsAsideLayout: '分欄佈局風格',
		sixTitle: '佈局切換',
		sixDefaults: '默認',
		sixClassic: '經典',
		sixTransverse: '橫向',
		sixColumns: '分欄',
		tipText: '點擊下方按鈕，複製佈局配寘去`src/stores/themeConfig.ts`中修改。',
		copyText: '一鍵複製配寘',
		resetText: '一鍵恢復默認',
		copyTextSuccess: '複製成功！',
		copyTextError: '複製失敗！',
	},
	upgrade: {
		title: '新版本陞級',
		msg: '新版本來啦，馬上更新嘗鮮吧！ 不用擔心，更新很快的哦！',
		desc: '提示：更新會還原默認配寘',
		btnOne: '殘忍拒絕',
		btnTwo: '馬上更新',
		btnTwoLoading: '更新中',
	},
};
