/**
 * 数字
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyNumeric(val: string) {
	return /^[-]?\\d+[.]?\\d*$/.test(val);
}

/**
 * 整数
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyNumber(val: string) {
	return /^\d+$/.test(val);
}

/**
 * 小数
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyDecimal(val: string) {
	return /^[0-9]+[.]?[0-9]+$/.test(val);
}

/**
 * 电话
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyPhone(val: string) {
	return /^(((\(0\d{2}\)|0\d{2})[- ]?)?\d{8}|((\(0\d{3}\)|0\d{3})[- ]?)?\d{7})(-\d{3})?$/.test(val);
}

/**
 * 手机
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyMobile(val: string) {
	return /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(val);
}

/**
 * 电话手机
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyPhoneAndMobile(val: string) {
	return /^(\((\+)?86\)|((\+)?86)?)0?1[3456789]\d{9}$|^(((\(0\d{2}\)|0\d{2})[- ]?)?\d{8}|((\(0\d{3}\)|0\d{3})[- ]?)?\d{7})(-\d{3})?$/.test(val);
}

/**
 * 用户名
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyUserName(val: string) {
	return /^([\u4e00-\u9fa5A-Za-z_0-9]{0,})$/.test(val);
}

/**
 * 中文
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyChinese(val: string) {
	return /[\u4e00-\u9fa5]/.test(val);
}

/**
 * 字母
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyLetter(val: string) {
	return /^[A-Za-z]+$/.test(val);
}

/**
 * 字母数字
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyLetterNum(val: string) {
	return /^[A-Za-z0-9]+$/.test(val);
}

/**
 * 大写字母
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyUpLetter(val: string) {
	return /^[A-Z]+$/.test(val);
}

/**
 * 大写字母数字
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyUpLetterNum(val: string) {
	return /^[A-Z0-9]+$/.test(val);
}

/**
 * 小写字母
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyLowLetter(val: string) {
	return /^[a-z]+$/.test(val);
}

/**
 * 小写字母数字
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyLowLetterNum(val: string) {
	return /^[a-z0-9]+$/.test(val);
}

/**
 * 身份证
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyIdCard(val: string) {
	return /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(val);
}

/**
 * 车牌号
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyCarNum(val: string) {
	return /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/.test(val);
}

/**
 * 信用代码
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyCreditCode(val: string) {
	return /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/.test(val);
}

/**
 * 银行卡号
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyBankCard(val: string) {
	return /^[1-9]\\d{9,29}$/.test(val);
}

/**
 * 邮箱
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyEmail(val: string) {
	return /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/.test(val);
}

/**
 * 网址
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyURL(val: string) {
	return /^(((ht|f)tps?|file):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/.test(val);
}

/**
 * 文件路径
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyFilePath(val: string) {
	return /^[a-zA-Z]:\\(?:\w+\\)*\w+\.\w+$/.test(val);
}

/**
 * IP地址
 * @param val 当前值字符串
 * @returns 返回 bool
 */
export function verifyIPAddress(val: string) {
	return /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/.test(
		val
	);
}

/**
 * 姓名
 * @param val 当前值字符串
 * @returns 返回 true: 姓名正确
 */
export function verifyFullName(val: string) {
	return /^[\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2}$/.test(val);
}

/**
 * 邮政编码
 * @param val 当前值字符串
 * @returns 返回 true: 邮政编码正确
 */
export function verifyPostalCode(val: string) {
	return /^[1-9][0-9]{5}$/.test(val);
}

/**
 * 验证表单字段是否是数字
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkNumeric(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyNumeric(value)) {
		callback();
	} else {
		callback(new Error('只支持数字'));
	}
}

/**
 * 验证表单字段是否是整数
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkNumber(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyNumber(value)) {
		callback();
	} else {
		callback(new Error('只支持整数'));
	}
}

/**
 * 验证表单字段是否是小数
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkDecimal(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyDecimal(value)) {
		callback();
	} else {
		callback(new Error('只支持小数'));
	}
}

/**
 * 验证表单字段是否是电话
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkPhone(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyPhone(value)) {
		callback();
	} else {
		callback(new Error('电话号码不正确'));
	}
}

/**
 * 验证表单字段是否是手机
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkMobile(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyMobile(value)) {
		callback();
	} else {
		callback(new Error('手机号不正确'));
	}
}

/**
 * 验证表单字段是否是电话手机
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkPhoneAndMobile(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyPhoneAndMobile(value)) {
		callback();
	} else {
		callback(new Error('只支持电话或手机号'));
	}
}

/**
 * 验证表单字段是否是用户名
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkUserName(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyUserName(value)) {
		callback();
	} else {
		callback(new Error('只支持字母数字下划线'));
	}
}

/**
 * 验证表单字段是否是中文
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkChinese(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyChinese(value)) {
		callback();
	} else {
		callback(new Error('只支持中文'));
	}
}

/**
 * 验证表单字段是否是字母
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkLetter(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyLetter(value)) {
		callback();
	} else {
		callback(new Error('只支持字母'));
	}
}

/**
 * 验证表单字段是否是字母数字
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkLetterNum(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyLetterNum(value)) {
		callback();
	} else {
		callback(new Error('只支持字母数字'));
	}
}

/**
 * 验证表单字段是否是大写字母
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkUpLetter(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyUpLetter(value)) {
		callback();
	} else {
		callback(new Error('只支持大写字母'));
	}
}

/**
 * 验证表单字段是否是大写字母数字
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkUpLetterNum(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyUpLetterNum(value)) {
		callback();
	} else {
		callback(new Error('只支持大写字母或数字'));
	}
}

/**
 * 验证表单字段是否是小写字母
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkLowLetter(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyLowLetter(value)) {
		callback();
	} else {
		callback(new Error('只支持小写字母'));
	}
}

/**
 * 验证表单字段是否是小写字母数字
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkLowLetterNum(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyLowLetterNum(value)) {
		callback();
	} else {
		callback(new Error('只支持小写字母或数字'));
	}
}

/**
 * 验证表单字段是否是身份证
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkIdCard(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyIdCard(value)) {
		callback();
	} else {
		callback(new Error('身份证号不正确'));
	}
}

/**
 * 验证表单字段是否是车牌号
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkCarNum(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyCarNum(value)) {
		callback();
	} else {
		callback(new Error('车牌号不正确'));
	}
}

/**
 * 验证表单字段是否是信用代码
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkCreditCode(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyCreditCode(value)) {
		callback();
	} else {
		callback(new Error('社会信用代码不正确'));
	}
}

/**
 * 验证表单字段是否是银行卡号
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkBankCard(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyBankCard(value)) {
		callback();
	} else {
		callback(new Error('银行卡号不正确'));
	}
}

/**
 * 验证表单字段是否是邮箱
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkEmail(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyEmail(value)) {
		callback();
	} else {
		callback(new Error('邮箱不正确'));
	}
}

/**
 * 验证表单字段是否是网址
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkURL(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyURL(value)) {
		callback();
	} else {
		callback(new Error('网址不正确'));
	}
}

/**
 * 验证表单字段是否是文件路径
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkFilePath(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyFilePath(value)) {
		callback();
	} else {
		callback(new Error('文件路径不正确'));
	}
}

/**
 * 验证表单字段是否是IP地址
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkIPAddress(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyIPAddress(value)) {
		callback();
	} else {
		callback(new Error('IP地址不正确'));
	}
}

/**
 * 验证表单字段是否是姓名
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkFullName(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyFullName(value)) {
		callback();
	} else {
		callback(new Error('姓名不正确'));
	}
}

/**
 * 验证表单字段是否是邮政编码
 * @param rule 规则信息，包含当前表单项的字段名
 * @param value 当前字段值
 * @param callback 回调
 */
export function checkPostalCode(rule: any, value: any, callback: any) {
	if (!value || value == '' || verifyPostalCode(value)) {
		callback();
	} else {
		callback(new Error('邮政编码不正确'));
	}
}
