import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios'
import { Local } from '/@/utils/storage';

/** 区域树节点*/
declare interface Region {
    n: string,
    v: string,
    c: Region[]
}

// /** 区域数据*/
// declare interface RegionData {
//     regionData: Region[]
// }

/**
 * 行政区域
 */
export const useRegionData = defineStore('regionData', () => {
    const regionData = ref<Region[]>([{ n: '未加载行政区域', v: '0', c: [] }]);

    /**
      * 行政区域树
      */
    const regionTree = computed((): Region[] => {
        return regionData.value;
    });

    /**
     * 行政区域字典
     */
    const regionDic = computed((): Record<string, string> => {
        const dic: Record<string, string> = {};
        const traverse = (node: Region, name: string = "") => {
            const fullName = name + node.n;
            dic[node.v] = fullName;
            node.c?.forEach(child => traverse(child, fullName));
        };
        regionData.value.forEach(node => traverse(node));
        return dic;
    });

    /**
     * 重写行政区域数据
     * https://registry.npmmirror.com/china-division/2.7.0/files/dist/pcas-code.json
     */
    async function setRegionData(forceRefresh: boolean = false): Promise<Region[]> {
            try {
                const data = Local.get('regionData');
                if (data && !forceRefresh) {
                    regionData.value = data;
                    return regionData.value as Region[];
                }

                const res = await axios.get('https://registry.npmmirror.com/china-division/2.7.0/files/dist/pcas-code.json')

                const transformItem = (item: any): Region => ({
                    v: item.code,
                    n: item.name,
                    c: item.children ? item.children.map(transformItem) : []
                });
                regionData.value = res.data.map(transformItem);
                Local.set('regionData', regionData.value)
            } catch (error) {
                regionData.value = [{ n: '行政区域加载失败', v: '0', c: [] }];
                console.log(error);
            }

            return regionData.value as Region[];
        }

    return { regionTree, regionDic, setRegionData };
});
