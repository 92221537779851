import { defineStore } from 'pinia';

import useUniformRequest from '/@/hooks/useUniformRequest';
import { DicCategoryApiFactory } from '/@/api/api';

/**
 * 字典列表
 * @methods setDictList 设置字典列表
 */
export const useDictList = defineStore('dictList', () => {
	const { data: dictList, refreshAsync } = useUniformRequest(DicCategoryApiFactory().getDicCategoryList);
	return { dictList, refreshAsync };
});
