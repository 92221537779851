
import useRequest, { PluginsMiddleOptionsType } from 'vue-hooks-plus/lib/useRequest/useRequest'
import { UseRequestOptions, UseRequestOptionsWithFormatResult, UseRequestOptionsWithInitialData, UseRequestPlugin, UseRequestService } from 'vue-hooks-plus/es/useRequest/types'
import { reactive, unref, UnwrapRef, watchEffect, toRefs } from 'vue'
import { UniformResult } from '../utils/request'
import { useUniformResult } from './interface'

// 同时存在 formatResult 和 initialData
export function useUniformRequest<
  TData,
  TParams extends unknown[] = unknown[],
  PluginsOptions extends UseRequestPlugin<UniformResult<TData>, TParams>[] = UseRequestPlugin<UniformResult<TData>, TParams>[],
  SR = any
>(
  service: UseRequestService<SR, TParams>,
  options: UseRequestOptionsWithFormatResult<
    UniformResult<TData>,
    TParams,
    PluginsMiddleOptionsType<PluginsOptions, UniformResult<TData>, TParams>,
    SR
  > &
    UseRequestOptionsWithInitialData<SR, TParams, PluginsOptions>,
  plugins?: PluginsOptions,
): useUniformResult<TData, TParams>

// 只有 formatResult
export function useUniformRequest<
  TData,
  TParams extends unknown[] = unknown[],
  PluginsOptions extends UseRequestPlugin<UniformResult<TData>, TParams>[] = UseRequestPlugin<UniformResult<TData>, TParams>[],
  SR = any
>(
  service: UseRequestService<SR, TParams>,
  options: UseRequestOptionsWithFormatResult<
    UniformResult<TData>,
    TParams,
    PluginsMiddleOptionsType<PluginsOptions, UniformResult<TData>, TParams>,
    SR
  >,
  plugins?: PluginsOptions,
): useUniformResult<TData, TParams>

// 只有 initialData
export function useUniformRequest<
  TData,
  TParams extends unknown[] = unknown[],
  PluginsOptions extends UseRequestPlugin<UniformResult<TData>, TParams>[] = UseRequestPlugin<UniformResult<TData>, TParams>[]
>(
  service: UseRequestService<UniformResult<TData>, TParams>,
  options: UseRequestOptionsWithInitialData<
    UniformResult<TData>,
    TParams,
    PluginsMiddleOptionsType<PluginsOptions, UniformResult<TData>, TParams>
  >,
  plugins?: PluginsOptions,
): useUniformResult<TData, TParams>

// 无 formatResults 和 initialData
export function useUniformRequest<
  TData,
  TParams extends unknown[] = unknown[],
  PluginsOptions extends UseRequestPlugin<UniformResult<TData>, TParams>[] = UseRequestPlugin<UniformResult<TData>, TParams>[]
>(
  service: UseRequestService<UniformResult<TData>, TParams>,
  options?: UseRequestOptions<
    UniformResult<TData>,
    TParams,
    PluginsMiddleOptionsType<PluginsOptions, UniformResult<TData>, TParams>
  >,
  plugins?: PluginsOptions,
): useUniformResult<TData, TParams>

export function useUniformRequest<
  TData,
  TParams extends unknown[] = unknown[],
  PluginsOptions extends UseRequestPlugin<UniformResult<TData>, TParams>[] = UseRequestPlugin<UniformResult<TData>, TParams>[]
>(
  service: UseRequestService<UniformResult<TData>, TParams>,
  options?: UseRequestOptions<
    UniformResult<TData>,
    TParams,
    PluginsMiddleOptionsType<PluginsOptions, UniformResult<TData>, TParams>
  >,
  plugins?: PluginsOptions,
): useUniformResult<TData, TParams> {
    const { data: reqData, ...restReq } = useRequest(service, options, plugins)
    const req = reactive<UniformResult<TData>>({
      data: reqData.value?.data,
      statusCode: reqData.value?.statusCode ?? 0,
      succeeded: reqData.value?.succeeded ?? false,
      errors: reqData.value?.errors,
      timestamp: reqData.value?.timestamp ?? 0
    });

    watchEffect(() => {
        const data = unref(reqData);
        req.data = data?.data as UnwrapRef<TData>;
        req.errors = data?.errors;
        req.statusCode = data?.statusCode ?? 0;
        req.succeeded = data?.succeeded ?? false;
        req.extras = data?.extras;
        req.timestamp = data?.timestamp ?? 0;
    });

    return { reqData, ...toRefs(req), ...restReq } as useUniformResult<TData, TParams>
}

export default useUniformRequest